exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-quote-jsx": () => import("./../../../src/pages/quote.jsx" /* webpackChunkName: "component---src-pages-quote-jsx" */),
  "component---src-pages-services-copywriting-jsx": () => import("./../../../src/pages/services/copywriting.jsx" /* webpackChunkName: "component---src-pages-services-copywriting-jsx" */),
  "component---src-pages-services-email-marketing-jsx": () => import("./../../../src/pages/services/email-marketing.jsx" /* webpackChunkName: "component---src-pages-services-email-marketing-jsx" */),
  "component---src-pages-services-hosting-and-support-jsx": () => import("./../../../src/pages/services/hosting-and-support.jsx" /* webpackChunkName: "component---src-pages-services-hosting-and-support-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-services-seo-jsx": () => import("./../../../src/pages/services/seo.jsx" /* webpackChunkName: "component---src-pages-services-seo-jsx" */),
  "component---src-pages-services-web-design-jsx": () => import("./../../../src/pages/services/web-design.jsx" /* webpackChunkName: "component---src-pages-services-web-design-jsx" */),
  "component---src-pages-services-web-development-jsx": () => import("./../../../src/pages/services/web-development.jsx" /* webpackChunkName: "component---src-pages-services-web-development-jsx" */),
  "component---src-pages-services-website-optimisation-jsx": () => import("./../../../src/pages/services/website-optimisation.jsx" /* webpackChunkName: "component---src-pages-services-website-optimisation-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-work-jsx": () => import("./../../../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */)
}

